<template>
  <v-container fluid>
    <v-card flat outlined>
      <v-card-title>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-icon color="red" @click="removePatents" dark large dense>
              cancel
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="certificationFrom">
          <v-row>
            <v-text-field
              outlined
              label="Patent Name"
              required
              filled
              v-model="patentEntity.patentName"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field outlined label="Patent Number"
             v-model="patentEntity.patentNumber"
             filled></v-text-field>
          </v-row>

          <v-row>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                 v-model="patentEntity.yearGranted"
                  label="Patent grant year"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  filled
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker type="month" @input="menu = false"
              v-model="patentEntity.yearGranted"
              ></v-date-picker>
            </v-menu>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "PatentForm",
   data() {
    return {
      menu: false,
      valid: false,
      menu1: false,
      type: "Patent"
    };
  },
  props: ["patentEntity", "index"],
  methods: {
    removePatents() {
      this.$store.dispatch("trainerProfile/updateTheScrappedDate", {
        type: this.type,
        index: this.index,
        confirmDelete:false
      });
    }
  }
};
</script>

<style></style>
