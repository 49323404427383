<template>
  <v-container fluid>
    <v-card flat outlined>
      <v-card-title>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-icon color="red" @click="removeCertifications" dark large dense>
              cancel
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="certificationFrom">
          <v-row>
            <v-text-field
              outlined
              v-model="certiEntity.certificationName"
              label="Certificate Name"
              required
              filled
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              outlined
               v-model="certiEntity.authorityName"
              label="Certification issuer"
              filled
            ></v-text-field>
          </v-row>

          <v-row>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Certification issue date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  filled
                  v-model="certiEntity.awardedDate"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker 
              v-model="certiEntity.awardedDate"
               @input="menu = false"></v-date-picker>
            </v-menu>
          </v-row>
          <v-row>
            <v-checkbox
              class="text-h6 font-weight-bold text--primary"
              label="Is certtificate non expiring ?"
            />
          </v-row>
          <v-row>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                   v-model="certiEntity.awardedDate"
                  label="Certification valid till"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                
                 v-model="certiEntity.awardedDate"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "CertificateFrom",
  data() {
    return {
      menu: false,
      valid: false,
      menu1: false,
      type: "Certification"
    };
  },
  props: ["certiEntity", "index"],
  methods: {
    removeCertifications() {
      this.$store.dispatch("trainerProfile/updateTheScrappedDate", {
        type: this.type,
        index: this.index,
        confirmDelete:false
      });
    }
  }
};
</script>

<style></style>
