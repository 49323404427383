<template>
  <v-container fluid>
    <v-row justify="center">
      <div class="text-h5 my-2 font-weight-bold text--primary">
        Create trainer's account
      </div>
    </v-row>
    <v-row>
      <div class="text-h6 my-2 font-weight-bold text--primary">
        Scrapped data of the trainer
      </div>
    </v-row>
    <v-row class="my-2">
      <v-card flat width="1600px">
        <v-stepper non-linear v-model="e1">
          <v-stepper-header class="my-2">
            <v-stepper-step step="1" editable :complete="e1 > 1">
              Trainer intro profile data
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2" :complete="e1 > 2">
              Trainer work experince and education
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" editable :complete="e1 > 3">
              Trainer certificates ,awards ,patents and publication
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="my-5">
            <v-stepper-content step="1">
              <v-col>
                <v-card flat class="my-2">
                  <v-text-field
                    label="First name"
                    outlined
                    :disabled="linkedInScrapperDto.trainerProfile.trainerId"
                    v-model="linkedInScrapperDto.trainerProfile.firstName"
                    placeholder=""
                    filled
                  ></v-text-field>
                  <v-text-field
                    label="Last name"
                     :disabled="linkedInScrapperDto.trainerProfile.trainerId"
                    outlined
                    v-model="linkedInScrapperDto.trainerProfile.lastName"
                    placeholder=""
                    filled
                  ></v-text-field>
                  <v-text-field
                   :disabled="linkedInScrapperDto.trainerProfile.trainerId"
                    label="Primary mobile number"
                    outlined
                    placeholder=""
                    filled
                    v-model="linkedInScrapperDto.trainerProfile.primaryPhoneNumber"
                  ></v-text-field>
                  <v-text-field
                   :disabled="linkedInScrapperDto.trainerProfile.trainerId"
                    label="Primary email id"
                    outlined
                    v-model="linkedInScrapperDto.trainerProfile.primaryEmailId"
                    placeholder=""
                    filled
                  ></v-text-field>
                  <v-text-field

                    label="City"
                    outlined
                    v-model="
                      linkedInScrapperDto.trainerProfile.currentAddressCity
                    "
                    placeholder=""
                    filled
                  ></v-text-field>
                  <v-text-field
                    label="State"
                    outlined
                    v-model="
                      linkedInScrapperDto.trainerProfile.currentAddressState
                    "
                    placeholder=""
                    filled
                  ></v-text-field>
                  <v-text-field
                    label="Country"
                    outlined
                    v-model="
                      linkedInScrapperDto.trainerProfile.currentAddressCountry
                    "
                    placeholder=""
                    filled
                  ></v-text-field>
                  <!-- <v-text-field
                    label="Website/blog"
                    outlined
                    v-model="linkedInScrapperDto.trainerProfile"
                    placeholder=""
                    filled
                  ></v-text-field> -->
                  <!-- <v-text-field
                    label="Headline"
                    outlined
                     v-model=""
                    placeholder=""
                    filled
                  ></v-text-field> -->
                  <v-textarea
                    label="Professional Overview"
                    outlined
                    v-model="
                      linkedInScrapperDto.trainerProfile.professionalOverview
                    "
                    placeholder=""
                    filled
                  ></v-textarea>
                  <v-textarea
                    outlined
                    placeholder=""
                    v-model="linkedInScrapperDto.trainerProfile.summary"
                    filled
                    label="Profile summary"
                  >
                  </v-textarea>
                 <div v-if="linkedInScrapperDto.languages.length>0">   
                  <v-row>
                <div class="text-h6 my-2 mx-3 font-weight-bold text--primary">
                    Languages
                  </div>
                </v-row>
                <v-list  v-for="(lang,
                  index) in linkedInScrapperDto.languages"
                  :key="index"
                 >
                  <v-row>
                  <v-col>
                    <v-text-field
                    
                    label="Language"
                    outlined
                    v-model="lang.language"
                    placeholder=""
                    filled
                  ></v-text-field>
                  </v-col>
                  <v-col>
                     <v-text-field
                     v-model="lang.profiency"
                    label="Proficiency"
                    outlined
                    placeholder=""
                    filled
                  ></v-text-field>
                  </v-col>
                </v-row>
                </v-list>
                </div>  
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-btn @click="e1 = 2" outlined color="blue" rounded
                        >Continue</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row>
                <div class="text-h6 my-2 mx-3 font-weight-bold text--primary">
                  Trainer work experince data
                </div>
              </v-row>
              <div v-if="linkedInScrapperDto.workExperince.length > 0">
                <v-list
                  v-for="(workExEntity,
                  index) in linkedInScrapperDto.workExperince"
                  :key="index"
                  color="#ECF0F3"
                >
                  <WorkExFrom :workExEntity="workExEntity" :index="index" />
                </v-list>
              </div>
              <v-row>
                <div class="text-h6 my-2 mx-3 font-weight-bold text--primary">
                  Education
                </div>
              </v-row>
              <div v-if="linkedInScrapperDto.education.length > 0">
                <v-list
                  v-for="(educationEntity,
                  index) in linkedInScrapperDto.education"
                  :key="index"
                  color="#ECF0F3"
                >
                  <EducationForm
                    :educationEntity="educationEntity"
                    :index="index"
                  />
                </v-list>
              </div>
              <v-row class="my-3" justify="space-around">
                <v-col cols="1">
                  <v-btn outlined color="blue" @click="e1 = 1">
                    Back
                  </v-btn>
                </v-col>
                <v-spacer> </v-spacer>
                <v-col cols="2">
                  <v-btn outlined color="blue" @click="e1 = 3">
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <div v-if="linkedInScrapperDto.certifications.length > 0">
                <v-row>
                  <div class="text-h6 my-2 mx-3 font-weight-bold text--primary">
                    Certifications
                  </div>
                </v-row>
                <v-list
                  v-for="(certiEntity,
                  index) in linkedInScrapperDto.certifications"
                  :key="index"
                  color="#ECF0F3"
                >
                  <CertifcationForm :certiEntity="certiEntity" :index="index" />
                </v-list>
              </div>

              <div v-if="linkedInScrapperDto.patents.length > 0">
                <v-row>
                  <div class="text-h6 my-2 mx-3 font-weight-bold text--primary">
                    Patents
                  </div>
                </v-row>
                <v-list
                  v-for="(patentEntity, index) in linkedInScrapperDto.patents"
                  :key="index"
                  color="#ECF0F3"
                >
                  <PatentFrom :index="index" :patentEntity="patentEntity" />
                </v-list>
              </div>
              <div v-if="linkedInScrapperDto.publication.length > 0">
                <v-row>
                  <div class="text-h6 my-2 mx-3 font-weight-bold text--primary">
                    Publications
                  </div>
                </v-row>
                <v-list
                  v-for="(publicationEntity,
                  index) in linkedInScrapperDto.publication"
                  :key="index"
                  color="#ECF0F3"
                >
                  <PublicationFrom
                    :index="index"
                    :publicationEntity="publicationEntity"
                  />
                </v-list>
              </div>
              <div v-if="linkedInScrapperDto.awards.length > 0">
                <v-row>
                  <div class="text-h6 my-2 mx-3 font-weight-bold text--primary">
                    Awards
                  </div>
                </v-row>
                <v-list
                  v-for="(awardEntity, index) in linkedInScrapperDto.awards"
                  :key="index"
                  color="#ECF0F3"
                >
                  <AwardFrom :awardEntity="awardEntity" :index="index" />
                </v-list>
              </div>
              <v-row class="my-3">
                <v-col cols="1">
                  <v-btn outlined @click="e1 = 2">
                    Back
                  </v-btn>
                </v-col>
                <v-spacer> </v-spacer>
                <v-col cols="3">
                  <v-btn outlined @click="updateTrainerProfile" v-if="linkedInScrapperDto.trainerProfile.trainerId">
                    Update Trainer Profile
                  </v-btn>
                  <v-btn outlined @click="createTrainerProfile" v-else>
                    Create trainer profile
                  </v-btn>
                  
                </v-col>
              </v-row>
            </v-stepper-content>
            <DeleteAlert :dialog="alertBoxScapper.status" :headerText="alertBoxScapper.headerText" :message="alertBoxScapper.message"  @onOk="confirmDelte" @onCancel="onCancel"/>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import WorkExFrom from "../../../components/ScrapperForms/workExFrom.vue";
import EducationForm from "../../../components/ScrapperForms/EducationFrom.vue";
import CertifcationForm from "../../../components/ScrapperForms/ceritficateFrom.vue";
import PatentFrom from "../../../components/ScrapperForms/patentForm.vue";
import PublicationFrom from "../../../components/ScrapperForms/publicationForm.vue";
import AwardFrom from "../../../components/ScrapperForms/AwardsFrom.vue";
import DeleteAlert from "../../../components/DeleteAlertBoxScrapper/deleteAlert.vue"
export default {
  name: "AddTrainerUsingLinkedIn",
  components: {
    WorkExFrom,
    EducationForm,
    CertifcationForm,
    PatentFrom,
    PublicationFrom,
    AwardFrom,
    DeleteAlert
  },
  data() {
    return {
      e1: 1,
      menu: false,
      valid: false,
      menu1: false
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      createProfile:'createProfile',
      linkedInScrapperDto: "linkedInScrapperDto",
      loading: "loading",
      alertBoxScapper:"alertBoxScapper"
    })
  },
  methods: {
    removeWorkEx() {},
    confirmDelte(){

      this.$store.dispatch("trainerProfile/updateTheScrappedDate", {
        type: this.alertBoxScapper.afterDeleteConfirmation.type,
        index: this.alertBoxScapper.afterDeleteConfirmation.index,
        confirmDelete:true
      });
    },
    onCancel(){
       this.$store.dispatch('trainerProfile/closeScrapperAlertBox')
    },
    createTrainerProfile(){
      this.$store.dispatch('trainerProfile/createTrainerUsingLinkedinScrapper' ,this.linkedInScrapperDto)
    },
    updateTrainerProfile(){
       this.$store.dispatch('trainerProfile/updateTheReamaiingDataFromScrapper' ,this.linkedInScrapperDto)
    }
  }
};
</script>

<style></style>
