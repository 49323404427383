<template>
  <v-container fluid>
    <v-card flat outlined>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-col cols="1">
          <v-icon color="red" @click="removeWorkEx" dark large dense>
            cancel
          </v-icon>
        </v-col>
      </v-card-title>
      <v-form ref="workExFrom">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="workExEntity.designation"
                label="Designation"
                filled
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="workExEntity.companyName"
                label="Company name"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="workExEntity.industry"
                label="Industry"
                required
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Nature of Engagement"
                required
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                v-model="workExEntity.rolesAndResponsibilites"
                label="Roles and Responsibilities"
                filled
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="workExEntity.currentlyWorking"
                class="text-h6 font-weight-bold text--primary"
                label="Is currently working here ?"
              />
            </v-col>
            <v-col cols="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    filled
                    v-model="workExEntity.startDate"
                    label="Start Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  type="month"
                   v-model="workExEntity.startDate"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" v-if="!workExEntity.currentlyWorking">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    filled
                    label="End Date"
                    v-model="workExEntity.endDate"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  type="month"
                  v-model="workExEntity.endDate"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "WorkEx",
  data() {
    return {
      menu: false,
      valid: false,
      menu1: false,
      type:"Work"
    };
  },
  props: ["workExEntity","index"],
  methods: {
    removeWorkEx() { 
        this.$store.dispatch('trainerProfile/updateTheScrappedDate'
        ,{
            type:this.type,
            index:this.index,
            confirmDelete:false
        })
       
    }
  }
};
</script>

<style></style>
